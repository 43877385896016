<style lang="scss" scoped>
.six-input-box {
    display: flex;
    flex-direction: row;
}

.input {
    display: flex;
    width: 25px;
    margin-left: 10px;
    height: 44px;
    font-size: 18px;
    color: #333333;
    background-color: #f2f2f2;
    text-align: center;
    outline: none;
    border: solid 1px #d2d2d2;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

.error-tips {
    margin-left: 10px;
    color: #f56c6c;
    font-size: 12px;
}
</style>
<template>
    <!-- desensitizeEmail() -->
    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;margin-top: 30vh;">
        <div style="font-size: 21px;text-align: center;line-height: 40px;">
            We have sent a verification code to your email
            <span style="font-weight: bold;color: #409eff;">{{ userInfoData.email }}</span> <br>
            please enter the verification code in your email to bind your PayPal account.
        </div>
        <div>
            <el-input v-model="inputVal" placeholder="input code" maxlength="6" @input="elInputChange"
                @keypress="onlyNumbers" />
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { ElMessage, ElLoading } from 'element-plus'
import { useStore } from 'vuex'
import request from '@/utils/request.js'

import axios from 'axios'
const store = useStore()
const route = useRoute()
const router = useRouter()
const code = ref('')
const idToken = ref('')
const returnObj = ref({})
const resbody = ref({})
const userInfoData = ref({})
const inputVal = ref()
onMounted(() => {

    userInfoData.value = JSON.parse(store.state.userInfo)
    returnObj.value = getParameters(window.location.href)
    code.value = returnObj.value.code
    idToken.value = returnObj.value.id_token
    console.log(returnObj.value, 'returnObj.value')
    console.log('store', JSON.parse(store.state.userInfo))
    obtainPayPalUserInformation()
})
const getParameters = URL => JSON.parse(`{"${decodeURI(URL.split("?")[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`
)
// 请求解析获取paypal用户信息
async function obtainPayPalUserInformation() {
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    const { code, msg } = await request.post('/system/paypal/bind', { code: returnObj.value.code })
    // const { data: { code, msg } } = await axios.post('/lfy/paypal/bind', { code: returnObj.value.code }, {
    //     headers: {
    //         "Authorization": "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxMjgsInVzZXJfa2V5IjoiMjFlYjNmOTktNDA0OC00ZThjLTgwMTUtNjBjNDdkNDhiODI2IiwidXNlcm5hbWUiOiJkb3duZmFuZ0BzaW5hLmNvbSJ9.7UEw4qwElYaxwFJ0yy2-q6MEyTM6_K_6TQAB59_N7bBNr34MjdxauxowISxfrVWzbIwPJXsMhKLyhUlx0eEzRA"
    //     }
    // })
    if (code == 200) {
        ElMessage.success('Send the verification code, if you have not received it for a long time, please check the dustbin mail!')
        loading.close()
    } else {
        ElMessage.error(msg)
        loading.close()
    }
}
// 脱敏
function desensitizeEmail(email) {
    // 检查邮箱地址是否为空
    if (!email) {
        return '';
    }
    // 将邮箱地址拆分为用户名和域名部分
    const [username, domain] = email.split('@');

    // 如果用户名长度小于等于2，则保留第一个字符和最后一个字符，其他字符用星号代替
    const desensitizedUsername = username.length <= 2 ? `${username.charAt(0)}***${username.charAt(username.length - 1)}` : `${username.charAt(0)}**${username.charAt(username.length - 1)}`;

    // 返回脱敏后的邮箱地址
    return `${desensitizedUsername}@${domain}`;
}
function elInputChange(e) {
    if (e.length == 6) {
        verifyTheMailbox()
    }
}
async function verifyTheMailbox() {
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    const { code, msg } = await request.post('/system/paypal/check', { code: inputVal.value })
    // const { data: { code, msg } } = await axios.post('/lfy/paypal/check', { code: inputVal.value }, {
    //     headers: {
    //         "Authorization": "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxMjgsInVzZXJfa2V5IjoiMjFlYjNmOTktNDA0OC00ZThjLTgwMTUtNjBjNDdkNDhiODI2IiwidXNlcm5hbWUiOiJkb3duZmFuZ0BzaW5hLmNvbSJ9.7UEw4qwElYaxwFJ0yy2-q6MEyTM6_K_6TQAB59_N7bBNr34MjdxauxowISxfrVWzbIwPJXsMhKLyhUlx0eEzRA"
    //     }
    // })
    if (code == 200) {
        loading.close()
        if (window.opener) {
            setTimeout(() => {
                ElMessage.success('success!The window will close in 2 seconds.')
                window.close()
            }, 2000)
            console.log("当前窗口是子窗口");
        } else {
            setTimeout(() => {
                ElMessage.success('success!Jump to Personal Data in 2 seconds')
                router.replace('/profileInfo/PersonalData')
            }, 2000)
            console.log("当前窗口是父窗口");
        }
    } else {
        ElMessage.error(msg)
        loading.close()
    }
}

function onlyNumbers(event) {
    const keyCode = event.keyCode;
    if (keyCode < 48 || keyCode > 57) { event.preventDefault() }
}
</script>